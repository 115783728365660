export const ARBITRUM = 42161
export const AVALANCHE = 43114

export const addresses = {
    [ARBITRUM]: {
        Reader: '0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694',
        GLP: '0x3963FfC9dff443c2A94f21b129D429891E32ec18',
        GMX: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
        WETH: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
        GMXDex: '0x489ee077994B6658eAfA855C308275EAd8097C4A',
        Uniswap: '0x80A9ae39310abf666A87C743d6ebBD0E8C42158E',
        Quoter: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
        GMXMarket: '0x8580d65FcC22bbad0dca6d9d138aA717434Fe7cB',
        GMXMarketListing: '0x6423B94abaCF8Da5093fe89d682eB53A9ACf9b4B',
        PayinETHorUSDC: '0xdcD7A322d0c68A1D591A04150F79Ae686E1882f9',
        GMXRewardRouter: '0x159854e14A862Df9E39E1D128b8e5F70B4A3cE9B',
        stakedGmxTracker: '0x908C4D94D34924765f1eDc22A1DD098397c59dD4',
        bnGMX: '0x35247165119B69A40edD5304969560D0ef486921',
        sbfGMX: '0xd2d1162512f927a7e282ef43a362659e4f2a728f',
        vGMX: '0x199070ddfd1cfb69173aa2f7e20906f26b363004',
        vGLP: '0xa75287d2f8b217273e7fcd7e86ef07d33972042e',
        USDC: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
    },

    [AVALANCHE]: {
        Reader: '0x2eFEE1950ededC65De687b40Fd30a7B5f4544aBd',
        GLP: '0xD152c7F25db7F4B95b7658323c5F33d176818EE4',
        GMX: '0x62edc0692BD897D2295872a9FFCac5425011c661',
        WAVAX: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
        GMXDex: '0x9ab2De34A33fB459b538c43f251eB825645e8595',
        // TraderJoeGMXETH: '0x0c91a070f862666bbcce281346be45766d874d98',
        Quoter: '0xd76019A16606FDa4651f636D9751f500Ed776250',

        GMXMarket: '0x143a1BE3A6fDB24e2000eC3245D0ACAAF82a9b97',
        GMXMarketListing: '0x10C2bb8d3e17D24F55a6E3638EC80Edd5ba5FaaC',
        PayinAVAXorUSDC: '0x0F5F1B7973feC89ed3cDC99601cf2ADE2C17984d',

        GMXRewardRouter: '0xa192D0681E2b9484d1fA48083D36B8A2D0Da1809',
        stakedGmxTracker: '0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342',
        bnGMX: '0x8087a341d32d445d9ac8acc9c14f5781e04a26d2',
        sbfGMX: '0x4d268a7d4C16ceB5a606c173Bd974984343fea13',
        vGMX: '0x472361d3cA5F49c8E633FB50385BfaD1e018b445',
        vGLP: '0x62331A7Bd1dfB3A7642B7db50B5509E57CA3154A',
        USDC: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    },
}

export function getAddress(chainId, key) {
    if (!(chainId in addresses)) {
        throw new Error(`Unknown chain ${chainId}`)
    }
    if (!(key in addresses[chainId])) {
        throw new Error(`Unknown address key ${key}`)
    }
    return addresses[chainId][key]
}
